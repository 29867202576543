import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';

// import dashDark from '../assets/images/components/dash-dark.png';

import ReactPlayer from 'react-player';

const items = [
  {
    icon: <SportsGymnasticsIcon />,
    title: 'Planos individuais e empresarial',
    description:
      'Com a phorm, a saúde é para todos, por isso nos trazemos uma rede de parceiros que atendem planos individuais e empresarial com um preço JUSTO!',
    // imageLight: `url(https://www.youtube.com/watch?v=eidbhd0VQWk&ab_channel=AutoSuper)`,
    // imageDark: `url(${dashDark})`
  },
  {
    icon: <LocalOfferIcon />,
    title: 'Descontos e benefícios nunca são de mais!',
    description:
      'Por isso que alem dos melhores parceiros temos descontos com os melhores estabelecimentos. Com a Phorm você tem mais benefícios como descontos em produtos fitness, suplementação quadras e muito mais, busque em nossa rede de parceiros e descubra!',
    // imageLight: `url(https://www.youtube.com/watch?v=eidbhd0VQWk&ab_channel=AutoSuper)`,
    // imageDark: `url(https://www.youtube.com/watch?v=eidbhd0VQWk&ab_channel=AutoSuper)`
  },
  {
    icon: <DevicesOtherIcon />,
    title: 'Disponível em todas as plataformas',
    description:
      'A Phorm está sempre conectada a você, por isso que ela está disponível para Android e IOS, busque a gente na sua loja de aplicativos e descubra o que a Phorm pode fazer por sua saúde!',
    // imageLight: `url(https://www.youtube.com/watch?v=eidbhd0VQWk&ab_channel=AutoSuper)`,
    // imageDark: `url(https://www.youtube.com/watch?v=eidbhd0VQWk&ab_channel=AutoSuper)`
  }
];

export default function AboutPlatform() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };


  return (
    <Container id="AboutPlatform" sx={{ pt: { xs: 10, sm: 14 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={7}>
          <div>
            <Typography component="h2" variant="h2" color="text.primary">
              Conheça a sua nova plataforma fitness e mude a sua vida para sempre!
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 2 }, mt: { xs: 2, sm: 2 } }}
              >
              Conheça a PHORM, chegamos para modernizar e facilitar o relacionamento entre você e as academias.
              {'\n'}
              Com planos a partir de R$ 24,90 você terá uma nova experiência em relação sua saúde, contamos com:
            </Typography>
          </div>

          {/* Mobile */}
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
          {items.map(({ icon, title, description }, index) => (
              <Card
              key={index}
              variant="outlined"
              component={Button}
              onClick={() => handleItemClick(index)}
              sx={{
                p: 2,
                height: 'fit-content',
                width: '100%',
                background: 'none',
                backgroundColor: selectedItemIndex === index ? 'action.selected' : undefined,
                borderColor: (theme) => {
                  if (theme.palette.mode === 'light') {
                    return selectedItemIndex === index ? 'primary.light' : 'grey.200';
                  }
                  return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                }
              }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  textAlign: 'left',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: { md: 'center' },
                  gap: 2.5
                }}>
                <Box sx={{ textTransform: 'none' }}>
                  <Typography color="text.primary" variant="body2" fontWeight="bold">
                    {title}
                  </Typography>
                  <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                    {description}
                  </Typography>
                </Box>
              </Box>
            </Card>
            ))}
          </Grid>

          {/*  */}
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
              width: '100%',
              height: 320
            }}>
              <ReactPlayer playing={false} width={'100%'} height='100%' url="https://youtu.be/mqeF-V6aWwA"/>
          </Box>

          {/* WEEB */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}>
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 2,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor: selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  }
                }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5
                  }}>
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index ? 'primary.main' : 'grey.300';
                        }
                        return selectedItemIndex === index ? 'primary.main' : 'grey.700';
                      }
                    }}>
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography color="text.primary" variant="body2" fontWeight="bold">
                      {title}
                    </Typography>
                    <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid  xs={12} md={5} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%', marginTop:5, paddingLeft: 5 }}>
          <ReactPlayer playing={false} width={'100%'} height='100%' url="https://youtu.be/mqeF-V6aWwA"/>
        </Grid>
      </Grid>
    </Container>
  );
}
